<template>
  <div>
    <SpinnerLoader v-if="costsStatus !== 'success'" :loading="costsStatus" />
    <template v-else-if="costsStatus === 'success' && costs && costs[selectedTab]">
      <virtual-scroll :items="costs[selectedTab].items" :item-height="28.6" class="vscroll table-responsive mb-0 costs" :class="{ 'with-tabs': tabsCount > 1 }">
        <template slot-scope="{ items }">
          <table class="table table-sm small table-hover table-bordered ws-nowrap sticky-headers sticky-footer">
            <thead>
              <tr>
                <th :rowspan="rowSpanCount">{{ $t('table.date') }}</th>
                <th :rowspan="rowSpanCount">
                  <div class="resizable channel_name">{{ $t('channelModal.channel') }}</div>
                </th>
                <th :rowspan="rowSpanCount">
                  <div class="resizable program_release">{{ $t('table.programRelease') }}</div>
                </th>
                <th :rowspan="rowSpanCount">{{ $t('table.time') }}</th>
                <th :rowspan="rowSpanCount">{{ $t('table.time') }} ({{ $t('broadcastSideBar.block') }})</th>
                <th :rowspan="rowSpanCount">
                  <div class="resizable commercial_type">{{ $t('table.commercialType') }}</div>
                </th>
                <th :rowspan="rowSpanCount">
                  <div class="resizable project">{{ $t('table.project') }}</div>
                </th>
                <th :rowspan="rowSpanCount">
                  <div class="resizable order">{{ $t('table.order') }}</div>
                </th>
                <th :rowspan="rowSpanCount">
                  <div class="resizable media_plan">{{ $t('table.mediaPlan') }}</div>
                </th>
                <th :rowspan="rowSpanCount">
                  <div class="resizable agency">{{ $t('table.agency') }}</div>
                </th>
                <th :rowspan="rowSpanCount">
                  <div class="resizable advertiser">{{ $t('table.advertiser') }}</div>
                </th>
                <th :rowspan="rowSpanCount">
                  <div class="resizable brand">{{ $t('table.brand') }}</div>
                </th>
                <th :rowspan="rowSpanCount">
                  <div class="resizable commercial">{{ $t('table.commercial') }}</div>
                </th>
                <th :rowspan="rowSpanCount">
                  <div class="resizable version">{{ $t('table.version') }}</div>
                </th>
                <th :rowspan="rowSpanCount">
                  <div class="resizable type">{{ $t('table.type') }}</div>
                </th>
                <th :rowspan="rowSpanCount" class="text-right">{{ $t('table.durationShort') }}</th>
                <th :rowspan="rowSpanCount">{{ $t('table.position') }}</th>
                <th :rowspan="rowSpanCount">
                  <div class="resizable target">{{ $t('channelModal.target') }}</div>
                </th>
                <th :rowspan="rowSpanCount" name="prime">{{ $t('channelModal.prime') }} ({{ $t('table.plan') }})</th>
                <th :rowspan="rowSpanCount" name="prime">{{ $t('channelModal.prime') }} ({{ $t('table.fact') }})</th>
                <th :rowspan="rowSpanCount" class="text-right">{{ $t('table.time') }} ({{ $t('table.plan') }})</th>
                <th :rowspan="rowSpanCount" class="text-right">{{ $t('table.time') }} ({{ $t('table.fact') }})</th>
                <th :rowspan="rowSpanCount" class="text-right">{{ $t('table.plan') }} {{ !showEquals ? 'wGRP' : $t('equalsWGRP.equals') }}</th>
                <th :rowspan="rowSpanCount" class="text-right">
                  {{ $t('table.plan') }}+{{ $t('table.fact') }} {{ !showEquals ? 'wGRP' : $t('equalsWGRP.equals') }}
                </th>
                <th :rowspan="rowSpanCount" class="text-right">{{ $t('table.fact') }} {{ !showEquals ? 'wGRP' : $t('equalsWGRP.equals') }}</th>
                <th :rowspan="rowSpanCount" class="text-right">{{ $t('table.price') }} ({{ $t('table.plan') }})</th>
                <th :rowspan="rowSpanCount" class="text-right">{{ $t('table.price') }} ({{ $t('table.fact') }})</th>
                <th :rowspan="rowSpanCount" class="text-right">{{ $t('table.plan') }} {{ $t('table.discount') }}(-)/<br />{{ $t('table.surcharge') }}(+)</th>
                <th :rowspan="rowSpanCount" class="text-right">{{ $t('table.fact') }} {{ $t('table.discount') }}(-)/<br />{{ $t('table.surcharge') }}(+)</th>
                <th v-if="auctionEnabled" :rowspan="rowSpanCount" class="text-right white-space-initial">
                  {{ $t('auction.auctionBid') }}
                </th>
                <!-- OPTIONAL DISCOUNTS DETAILS GO HERE -->
                <th v-if="with_discount_details" :colspan="discountDetailsColSpanCount + 4">
                  {{ $t('reports.discountDetails') }}
                </th>
                <th :rowspan="rowSpanCount" class="text-right">{{ $t('table.plan') }}</th>
                <th :rowspan="rowSpanCount" class="text-right">{{ $t('table.plan') }}+{{ $t('table.fact') }}</th>
                <th :rowspan="rowSpanCount" class="text-right">{{ $t('table.fact') }}</th>
              </tr>
              <!-- OPTIONAL DISCOUNTS DETAILS GO HERE -->
              <template v-if="with_discount_details">
                <tr>
                  <th v-for="(d, dIndex) in costs[selectedTab].totals.discounts" :key="dIndex">
                    <div class="resizable discount-name white-space-initial" :title="d.name">{{ d.name }}</div>
                  </th>
                  <th>
                    <div class="resizable discount-name white-space-initial">{{ $t('reports.surchargeForPremiumPosition') }}</div>
                  </th>
                  <th>
                    <div class="resizable discount-name white-space-initial">{{ $t('table.discountForAutomatic') }}</div>
                  </th>
                  <th>
                    <div class="resizable discount-name white-space-initial">{{ $t('table.discountForLowPriority') }}</div>
                  </th>
                  <th>
                    <div class="resizable discount-name white-space-initial">{{ $t('table.surchargeForCoBrand') }}</div>
                  </th>
                </tr>
              </template>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="`${item.project_id}_${item.order_id}_${item.mediaplan_id}_${item.commercial_id}_${item.date}_${item.spot_start_interval}_${item.spot_fact_start_interval}_${index}`"
              >
                <td>{{ item.date | convertDate }}</td>
                <td name="channel_name">{{ item.channel_name }}</td>
                <td name="program_release">
                  <template v-if="item.program_release_start_interval != null">
                    {{ item.program_release_name }}
                  </template>
                  <i v-else>
                    {{ $t('table.crossProgramBlock') }}
                  </i>
                </td>
                <td>
                  <template v-if="item.program_release_start_interval != null">
                    {{ sec2time(item.program_release_start_interval) }}
                  </template>
                  <i v-else-if="item.block_start_interval != null">
                    {{ item.block_start_interval ? sec2time(item.block_start_interval) : '' }}
                  </i>
                </td>
                <td>{{ item.block_start_interval != null ? sec2time(item.block_start_interval) : '' }}</td>
                <td name="commercial_type">{{ item.commercial_type_name }}</td>
                <td name="project">{{ item.project_name }}</td>
                <td name="order">{{ item.order_name }}</td>
                <td name="media_plan">{{ item.mediaplan_name }}</td>
                <td name="agency">{{ item.agency_name }}</td>
                <td name="advertiser">{{ item.advertiser_name }}</td>
                <td name="brand">{{ item.brand_name }}</td>
                <td name="commercial">{{ item.commercial_name }}</td>
                <td name="version">{{ item.commercial_version_type_name }}</td>
                <td name="type">{{ item.placement_type_name }}</td>
                <td class="text-right">{{ item.commercial_duration }}</td>
                <td :class="{ 'bg-position-warning': item.spot_position && item.spot_equal_fact_position === 0 }">{{ item.spot_position }}</td>
                <td name="target">{{ item.target_audience_name }}</td>
                <td>{{ item.is_prime_time_plan !== null ? (item.is_prime_time_plan ? $t('channelModal.prime') : $t('channelModal.offPrime')) : '' }}</td>
                <td>{{ item.is_prime_time_fact !== null ? (item.is_prime_time_fact ? $t('channelModal.prime') : $t('channelModal.offPrime')) : '' }}</td>
                <td>{{ item.spot_start_interval != null ? sec2time(item.spot_start_interval) : '' }}</td>
                <td>{{ item.spot_fact_start_interval != null ? sec2time(item.spot_fact_start_interval) : '' }}</td>
                <td class="text-right">{{ item.plan_wgrp | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.plan_fact_wgrp | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.fact_wgrp | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.basic_cpp_price_plan | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.basic_cpp_price_fact | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.plan_discount ? (+item.plan_discount).toFixed(2) + '%' : '' }}</td>
                <td class="text-right">{{ item.fact_discount ? (+item.fact_discount).toFixed(2) + '%' : '' }}</td>
                <td v-if="auctionEnabled" class="text-right">
                  <div v-if="item.spot_auction_coeff">{{ item.spot_auction_coeff * 100 }}%</div>
                </td>
                <template v-if="with_discount_details">
                  <!-- OPTIONAL DISCOUNTS DETAILS GO HERE -->
                  <td v-for="(discount, discountIndex) in costs[selectedTab].totals.discounts" :key="discountIndex" class="text-right" name="discount-name">
                    <template v-if="item.discounts[discountIndex] != null"> {{ item.discounts[discountIndex].percent | toFixedAndSpace }}% </template>
                  </td>
                  <td class="text-right" name="discount-name">
                    <template v-if="item.premium_position_discount != null"> {{ item.premium_position_discount | toFixedAndSpace }}%</template>
                  </td>
                  <td class="text-right" name="discount-name">
                    <template v-if="item.auto_placement_type_discount != null"> {{ item.auto_placement_type_discount | toFixedAndSpace }}%</template>
                  </td>
                  <td class="text-right" name="discount-name">
                    <template v-if="item.low_priority_type_discount != null"> {{ item.low_priority_type_discount | toFixedAndSpace }}%</template>
                  </td>
                  <td class="text-right" name="discount-name">
                    <template v-if="item.co_branding_discount != null">{{ item.co_branding_discount | toFixedAndSpace }}%</template>
                  </td>
                </template>
                <td class="text-right">{{ item.plan_sum | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.plan_fact_sum | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.fact_sum | toFixedAndSpace }}</td>
              </tr>
            </tbody>
            <tfoot class="font-weight-bold">
              <tr>
                <td>{{ $t('booking.total') }}</td>
                <td colspan="11"></td>
                <td class="text-right">{{ costs[selectedTab].totals.spot_count }}</td>
                <td colspan="9"></td>
                <td class="text-right">{{ costs[selectedTab].totals.plan_wgrp | toFixedAndSpace }}</td>
                <td class="text-right">{{ costs[selectedTab].totals.plan_fact_wgrp | toFixedAndSpace }}</td>
                <td class="text-right">{{ costs[selectedTab].totals.fact_wgrp | toFixedAndSpace }}</td>
                <td :colspan="auctionEnabled ? 5 : 4"></td>

                <!-- OPTIONAL DISCOUNTS DETAILS GO HERE -->
                <td v-if="with_discount_details" :colspan="discountDetailsColSpanCount + 4"></td>
                <td class="text-right">{{ costs[selectedTab].totals.plan_sum | toFixedAndSpace }}</td>
                <td class="text-right">{{ costs[selectedTab].totals.plan_fact_sum | toFixedAndSpace }}</td>
                <td class="text-right">{{ costs[selectedTab].totals.fact_sum | toFixedAndSpace }}</td>
              </tr>
            </tfoot>
          </table>
        </template>
      </virtual-scroll>
      <div
        v-if="tabsCount > 1"
        class="d-flex px-3 py-2 overflow-auto position-fixed"
        style="left: 0; bottom: 0; right: 0; white-space: nowrap; width: 100vw; z-index: 100"
      >
        <b-button v-for="(item, name) in costs" :key="name" size="sm" :variant="selectedTab === name ? 'primary' : 'link'" @click="changeActiveTab(name)">
          {{ name }}
        </b-button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerLoader from '@/components/SpinnerLoader';
import convertDate from '@/filters/convertDate';
import toFixedAndSpace from '@/filters/toFixedAndSpace';
import VirtualScroll from '@/components/VirtualScroll';
import timeConvertHelpers from '@/mixins/timeConvertHelpers';

export default {
  name: 'CostTable',
  components: { SpinnerLoader, VirtualScroll },
  filters: { convertDate, toFixedAndSpace },
  mixins: [timeConvertHelpers],
  props: {
    with_discount_details: { type: Boolean },
  },
  data() {
    return {
      selectedTab: 'All',
      auctionEnabled: process.env.VUE_APP_ENABLE_AUCTIONS === 'TRUE',
      is_equals_enabled: false,
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      costs: 'getReportCosts',
      costsStatus: 'getReportCostsStatus',
      sh_settings: 'getSalehousesSettings',
    }),
    showEquals() {
      return this.FEATURES.EQUALS && this.sh_settings[0]?.is_equals_enabled;
    },
    tabsCount() {
      if (this.costsStatus === 'success' && this.costs) {
        return Object.keys(this.costs).length;
      } else return 1;
    },
    rowSpanCount() {
      if (this.with_discount_details) return 2;
      else return 1;
    },
    discountDetailsColSpanCount() {
      if (this.costsStatus === 'success' && this.costs && this.with_discount_details) {
        return Object.keys(this.costs[this.selectedTab]?.totals.discounts)?.length ?? 1;
      } else return 1;
    },
  },
  watch: {
    costs() {
      if (this.costsStatus === 'success' && this.costs) {
        this.selectedTab = Object.keys(this.costs)[0];
      }
    },
    selectedTab() {
      // scroll to the top of wrapper on tab change
      const tableWrapper = document.querySelector('div.costs.vscroll-holder');
      if (tableWrapper) tableWrapper.scrollTop = 0;
    },
  },
  destroyed() {
    this.$store.commit('clearReportCosts');
  },
  methods: {
    changeActiveTab(name) {
      this.selectedTab = name;
    },
  },
};
</script>
<style lang="sass">
.reports-page div.table-responsive.costs
  height: calc(100vh - 210px)
  &.with-tabs
    height: calc(100vh - 250px)
  .white-space-initial
    white-space: initial

.reports-page .costs table.table
  div.resizable
    &.channel_name,
    &.program_release,
    &.commercial_type,
    &.project,
    &.order,
    &.media_plan,
    &.agency,
    &.advertiser,
    &.brand,
    &.commercial,
    &.version,
    &.type,
    &.target
      min-width: 46px
    &.discount-name
      min-width: 70px
      width: 100px

  th[name="prime"]
    min-width: 75px

  td[name="channel_name"],
  td[name="program_release"],
  td[name="commercial_type"],
  td[name="project"],
  td[name="order"],
  td[name="media_plan"],
  td[name="agency"],
  td[name="advertiser"],
  td[name="brand"],
  td[name="commercial"],
  td[name="version"],
  td[name="type"],
  td[name="target"]
    max-width: 46px
  td[name="discount-name"]
    max-width: 70px

@media (min-width: 2485px)
  .reports-page div.table-responsive.costs
    height: calc(100vh - 154px)
    &.with-tabs
      height: calc(100vh - 194px)
</style>
